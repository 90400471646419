import React from "react";
import StickyNavbar from "../../components/Navbar";

export default function About() {
    return (
        <div className="w-full px-0 md:px-6">
            <StickyNavbar />
            <section className="py-24 xl:py-32">
                <div className="container grid items-center gap-4 px-4 text-center md:gap-8 md:px-6 lg:grid-cols-2 lg:text-left">
                    <div className="space-y-4 md:space-y-6">
                        <div className="space-y-2">
                            <h1
                                className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
                                style={{ color: "#0f2137" }}
                            >
                                About Us
                            </h1>
                            <p
                                className="text-gray-500"
                                style={{ color: "#0f2137" }}
                            >
                                Mission &amp; Expertise
                            </p>
                        </div>
                        <div className="prose max-w-none">
                            <p style={{ color: "#0f2137" }}>
                                We're a team of passionate Nashikkars dedicated
                                to showcasing the beauty, culture, and vibrant
                                spirit of our city. My Nashik AI was born out of
                                our love for Nashik and our desire to leverage
                                the power of AI to create a platform that would
                                connect locals and visitors alike to everything
                                the city has to offer.
                                <br />
                                <br />
                                &emsp; &emsp; Whether you're a seasoned
                                Nashikkar looking for new adventures or a
                                first-time visitor exploring the city's
                                treasures, My Nashik AI is your go-to resource.
                                Our platform is designed to help you discover
                                hidden gems, plan the perfect itinerary, and
                                experience Nashik like a local, all powered by
                                advanced AI technology.
                            </p>
                        </div>
                    </div>
                    <div className="mx-auto">
                        <img
                            src="/icon.jpg"
                            //   width="500"
                            height="300"
                            alt="Hero"
                            className="rounded-xl w-100"
                            style={{
                                aspectRatio: "500/300",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                </div>
            </section>
            <section className="py-12 lg:py-24 xl:py-32">
                <div className="container grid items-center gap-4 px-6 text-left md:gap-8 md:px-8 lg:grid-cols-2 lg:text-left">
                    <div className="space-y-6 md:space-y-8">
                        <h1
                            className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center lg:text-left"
                            style={{ color: "#0f2137" }}
                        >
                            Why is My Nashik AI special?
                        </h1>
                        <ul className="list-disc space-y-2 prose ps-8">
                            <li>
                                <strong>AI-Powered Recommendations:</strong> Our
                                smart algorithms are like your personal Nashik
                                genie, granting you personalized recommendations
                                tailored to your tastes.
                            </li>
                            <li>
                                <strong>Real-Time Updates:</strong> We're always
                                on top of the latest happenings in Nashik, so
                                you'll never miss a beat.
                            </li>
                            <li>
                                <strong>Easy-to-Use Interface:</strong> Our
                                platform is as simple and intuitive as ordering
                                a pizza.
                            </li>
                            <li>
                                <strong>Community-Driven Content:</strong> We
                                rely on the insights and experiences of our
                                awesome community to keep our content fresh and
                                relevant.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* <section className="py-12 lg:py-24 xl:py-32">
                <div className="container grid items-center gap-4 px-4 text-center md:gap-8 md:px-6 lg:grid-cols-2 lg:text-left">
                    <div className="space-y-4 md:space-y-6">
                        <div className="space-y-2">
                            <h2
                                className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
                                style={{ color: "#0f2137" }}
                            >
                                Our Team
                            </h2>
                            <p
                                className="text-gray-500 "
                                style={{ color: "#0f2137" }}
                            >
                                Experts &amp; Innovators
                            </p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-4">
                                    <div className="w-12 h-12 rounded-full bg-gray-100 border overflow-hidden ">
                                        <img
                                            src="https://g-44nsqexsnoe.vusercontent.net/placeholder.svg"
                                            width="48"
                                            height="48"
                                            alt="Avatar"
                                            className="rounded-full object-cover w-full h-full"
                                            style={{
                                                aspectRatio: "48/48",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <h3 className="font-semibold">
                                            Alice Smith
                                        </h3>
                                        <p className="text-sm text-gray-500 ">
                                            Data Analyst
                                        </p>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-500/70 ">
                                    Alice is passionate about turning complex
                                    data into actionable insights. With a
                                    background in statistics and machine
                                    learning, she brings a data-driven approach
                                    to every project.
                                </p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-4">
                                    <div className="w-12 h-12 rounded-full bg-gray-100 border overflow-hidden ">
                                        <img
                                            src="https://g-44nsqexsnoe.vusercontent.net/placeholder.svg"
                                            width="48"
                                            height="48"
                                            alt="Avatar"
                                            className="rounded-full object-cover w-full h-full"
                                            style={{
                                                aspectRatio: "48/48",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <h3 className="font-semibold">
                                            Bob Johnson
                                        </h3>
                                        <p className="text-sm text-gray-500 ">
                                            Business Strategist
                                        </p>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-500/70 ">
                                    Bob has over a decade of experience helping
                                    companies develop growth strategies. He is a
                                    creative thinker who excels in identifying
                                    new opportunities.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto max-w-[400px] grid items-center justify-center gap-4">
                        <a
                            href="#"
                            className="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 bg-white shadow-sm text-sm font-medium px-4 transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 "
                            prefetch={false}
                        >
                            Join the Team
                        </a>
                    </div>
                </div>
            </section> */}
        </div>
    );
}
