import React, { useState } from "react";

const StickyNavbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    // const currentPath = window.location.pathname;

    return (
        <nav className="fixed top-0 right-0 w-fit bg-none z-50">
            <div className="mx-auto p-6 px-2 flex justify-between items-center">
                <div className="logo">
                    {/* <img src="logo192.png" alt="Logo" className="w-12 h-12" /> */}
                </div>
                <div className="nav-links hidden md:flex bg-white rounded p-4">
                    <ul className="flex space-x-4 text-base font-semibold">
                        <li>
                            <a
                                href="/"
                                className="text-gray-600 hover:text-gray-900 p-4"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="/about"
                                className="text-gray-600 hover:text-gray-900 p-4"
                            >
                                About us
                            </a>
                        </li>
                        <li>
                            <a
                                href="/history"
                                className="text-gray-600 hover:text-gray-900 p-4"
                            >
                                History
                            </a>
                        </li>
                        {/* <li>
                            <a
                                href="/updates"
                                className="text-gray-600 hover:text-gray-900 p-4"
                            >
                                Updates
                            </a>
                        </li> */}
                        <li>
                            <a
                                href="/contact"
                                className="relative bg-gray-900 text-white px-4 py-2 rounded overflow-hidden group"
                            >
                                <span className="absolute inset-0 bg-gray-600 scale-0 group-hover:scale-100 transition-transform duration-300 ease-out rounded-sm opacity-50"></span>
                                <span className="relative z-10">
                                    Contact us
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="md:hidden">
                    <button
                        onClick={toggleMenu}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-2 rounded mr-2"
                    >
                        <i className="fas fa-bars text-xl"></i>
                    </button>
                </div>
            </div>
            {/* Mobile menu popup */}
            {isMenuOpen && (
                <div className="absolute top-6 overflow-x-hidden left-0 mx-[3%] w-[94%] bg-white rounded-xl shadow-lg md:hidden">
                    <ul className="flex flex-col space-y-4 p-4 pt-2">
                        <li className="text-end">
                            <button
                                className="text-gray-600 hover:text-gray-900"
                                onClick={toggleMenu}
                            >
                                <i className="fas fa-times text-xl"></i>
                            </button>
                        </li>
                        {/* {currentPath !== "/" && ( */}
                        <li>
                            <a
                                href="/"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                Home
                            </a>
                        </li>
                        {/* )} */}
                        <li>
                            <a
                                href="/about"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                About us
                            </a>
                        </li>
                        {/* <li>
                            <a
                                href="/updates"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                Updates
                            </a>
                        </li> */}
                        <li>
                            <a
                                href="/history"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                History
                            </a>
                        </li>
                        <li>
                            <a
                                href="/contact"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                Contact us
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default StickyNavbar;
