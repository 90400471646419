import React from "react";
import StickyNavbar from "../../components/Navbar";

export default function History() {
    return (
        <div className=" md:mt-24 mt-20 mb-12 mx-2">
            <StickyNavbar />
            <div className="max-w-6xl mx-auto bg-gray-50 p-6 md:p-10 border-t tracking-tight">
                <h1 className="text-5xl font-bold my-4">History</h1>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Legend has it that the name "Nashik" has a rather...
                        colorful origin. It all started when Lord Ram, the Hindu
                        deity, was in exile with his wife Sita and brother
                        Lakshman. A wicked demoness named Shoorpanakha tried to
                        charm Lord Ram, but he wasn't impressed. In fact, he was
                        so annoyed that he asked Lakshman to cut off her nose!
                        This incident, according to the epic Ramayana, is where
                        the name "Nashik" (meaning "nose") comes from.{" "}
                    </p>
                    <p>
                        Nashik's history doesn't just revolve around
                        nose-cutting legends. It also has a rich political past.
                        In 1818, the Peshwas, powerful Hindu rulers, took
                        control of the city. But their reign was short-lived.
                        The British arrived on the scene and captured Nashik in
                        the same year.<br></br> Even though the British took
                        over, Nashik continued to grow and develop. In 1840, one
                        of the first modern libraries in the entire state of
                        Maharashtra was established right here in Nashik.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Mauryan Empire
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Long before the Peshwas and the British, Nashik was part
                        of a vast empire ruled by the Mauryans. Evidence of this
                        comes from an inscription issued by Dharma Maha Matra, a
                        high official of the Mauryan Emperor Ashoka. This
                        inscription, found in Chandrapur district, mentions the
                        capture and killing of animals in the Nashik region.
                    </p>
                    <p className="">
                        Ashoka's rock edicts also refer to the Rashtrika and
                        Bhoja-Petenika dynasties. Many historians believe that
                        the Petenikas lived in Pratishthana (modern-day
                        Paithan), the Rashtrikas ruled as powerful warriors, and
                        the Bhojas controlled Vidarbha.
                    </p>
                    While Nashik may not have been the center of power during
                    the Mauryan era, it was certainly an important part of their
                    vast kingdom. It was ruled by vassals, or local rulers who
                    pledged loyalty to the Mauryan Emperor. This period marked
                    the beginning of a long and complex history for Nashik.
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Satavahanas
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Just a few decades after the fall of the Mauryan Empire,
                        a new dynasty rose to power in Western Maharashtra: the
                        Satavahanas. Their founder, Simuka, laid the groundwork
                        for a prosperous and influential kingdom.
                    </p>
                    <p className="">
                        It was during the reign of Simuka's brother, Krishna
                        (circa 180 BCE), that the Nashik region became part of
                        the Satavahana Empire. Krishna left a lasting legacy by
                        excavating a cave near Nashik to house Buddhist monks.
                        This act of patronage reflects the Satavahanas'
                        commitment to religious tolerance and cultural
                        development.
                    </p>
                    <p className="">
                        The dynasty continued to flourish under the leadership
                        of Satakarni I (circa 170 BCE - 130 BCE) and his wife,
                        Nayanika. Their son, Vedishri (circa 130 BCE - 110 BCE),
                        was renowned for his bravery and military prowess,
                        earning the title "lord of Dakshinapatha" (Deccan).
                    </p>
                    <p className="">
                        However, the Satavahana empire faced challenges from
                        foreign invaders. The Shaka Kshatrapas, led by Nahapana
                        (circa 110 BCE - 120 CE), conquered parts of Western
                        Maharashtra, including Nashik. Nahapana's rule was
                        marked by significant cultural contributions, as
                        evidenced by the Pandu-lena caves excavated during his
                        reign.
                    </p>
                    <p className="">
                        The tide turned when Gautamiputra Satakarni (circa 120
                        CE - 170 CE), a descendant of Satakarni I, defeated
                        Nahapana and reclaimed Satavahana territory. His victory
                        over the Shakas solidified his reputation as a powerful
                        and capable ruler.
                    </p>
                    <p className="">
                        Under the leadership of Yajnashri Satakarni (circa 170
                        CE - 190 CE), the Satavahana empire reached its zenith.
                        His reign extended across a vast region, from the
                        western coast to the eastern Deccan. Nashik, under
                        Satavahana rule, became a thriving trade center,
                        renowned for its silk production. The city's prosperity
                        and cultural significance during this period left a
                        lasting mark on its history.
                    </p>
                    <p className="">
                        Unfortunately, the Satavahana dynasty eventually
                        fragmented, and its power waned. However, their legacy
                        of patronage, cultural development, and economic
                        prosperity continued to shape the region's identity.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Abhiras
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Following the decline of the Satavahanas, the Abhira
                        dynasty rose to prominence in the region. The Abhiras
                        ruled in the northeast, while the Chutus controlled
                        Maharashtra and Kuntala. According to Puranic texts, ten
                        Abhira kings ruled for a total of 67 years.
                    </p>
                    <p className="">
                        The Nashik inscription of King Madhuriputra Ishvarasena,
                        an Abhira ruler and son of Shivadatta, provides valuable
                        insights into the dynasty. This inscription dates back
                        to circa 249-250 CE, a period that later became known as
                        the Kalachuri or Chedi era.
                    </p>
                    <p className="">
                        During this time, Nashik was referred to as Trirashmi by
                        some Sanskrit poets. The founder of the Abhira dynasty,
                        Rajan Ishvarasena, son of Shivadatta, left an
                        inscription in cave IX at Nasik. This inscription
                        records his charitable contributions to the Buddhist
                        mendicants residing in the Viharas of Trirashmi.
                    </p>
                    <p className="">
                        Ishvarasena established a new era, beginning in 250 CE,
                        which later became known as the Kalachuri-Chedi era. The
                        earlier dates of this era are found in Northern
                        Maharashtra, Gujarat, Central India, and Vidarbha. The
                        widespread use of this era suggests that Ishvarasena and
                        his descendants ruled over a significant territory,
                        encompassing Gujarat, Konkan, and Northern Maharashtra.
                    </p>
                    <p className="">
                        Ishvarasena was succeeded by nine other Abhira kings,
                        who collectively ruled for 167 years. The Abhira
                        dynasty's rule in Nashik marked a new chapter in the
                        region's history, leaving its own mark on the cultural
                        and political landscape.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Traikutakas
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The Traikutaka dynasty, named after the Trikuta mountain
                        bordering the Nasik district, ruled for a relatively
                        brief period. Three Traikutaka kings, Indradatta,
                        Dahrasena, and Vyaghrasena, are known from their
                        inscriptions and coins found in Nashik and Gujarat.
                        Dahrasena, an independent king who performed an
                        Ashvamedha (a prestigious horse sacrifice), made a
                        significant donation to a Brahmana. This donation was
                        recorded in a copper-plate grant discovered at Pardi in
                        the Surat district.
                    </p>
                    <p className="">
                        Vyaghrasena, Dahrasena's son, succeeded him as ruler.
                        However, Vyaghrasena had to acknowledge the supremacy of
                        the Vakataka king Harishena. A copper-plate grant dated
                        490 CE (in the Abhira era), found at Surat, records
                        Vyaghrasena's donation of a village. The Traikutaka
                        dynasty's rule in Nashik was relatively short-lived, but
                        their presence left a mark on the region's history.
                        Their inscriptions and donations provide valuable
                        insights into the political and social landscape of
                        Nashik during this period.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Vishnukundins
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Following the decline of the Vakatakas in the early 6th
                        century CE, Vidarbha came under the control of the
                        Vishnukundin king Madhavavarman I. He married a Vakataka
                        princess, strengthening his ties to the region.
                    </p>
                    <p className="">
                        Taking advantage of the political vacuum created by the
                        Vakatakas' downfall, Madhavavarman I expanded his
                        dominion significantly. He performed numerous Vedic
                        sacrifices, including eleven Ashvamedhas, a prestigious
                        ritual symbolizing his sovereignty.
                    </p>
                    <p className="">
                        A copper-plate grant discovered at Khanapur in the
                        Satara district indicates that Madhavavarman I's rule
                        extended to Western Maharashtra. His grandson,
                        Madhavavarman II, described himself as the lord of
                        Trikuta and Malaya, suggesting that the Vishnukundins
                        may have had a presence in the Nashik region for a
                        period.
                    </p>
                    <p className="">
                        While the Vishnukundins' rule in Nashik was relatively
                        brief, their presence added another layer to the
                        region's complex history. Their influence and conquests
                        contributed to the evolving political landscape of the
                        time.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Kalachuris
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        Following the Vishnukundins, the Kalachuri king
                        Krishnaraja rose to power around 550 CE. He ruled from
                        Mahishmati, modern Maheshvara, in the former Indore
                        State. His coins have been found across a vast
                        territory, from Rajputana in the north to Maharashtra in
                        the south.
                    </p>
                    <p className="">
                        Krishnaraja's coins, known as Krishnarajarupakas, were
                        in circulation for over 150 years, as mentioned in the
                        Anjaneri plates dated 710-711 CE. This suggests the
                        enduring influence of the Kalachuri dynasty.
                    </p>
                    <p className="">
                        Krishnaraja was succeeded by his son Shankaragana, whose
                        copper-plate grant was discovered at Abhona in the Nasik
                        District. Dated 597 CE, this grant indicates that
                        Shankaragana ruled over an extensive kingdom, stretching
                        from Malva in the north to the Nasik and Aurangabad
                        districts in the south.
                    </p>
                    <p className="">
                        Shankaragana's son, Buddharaja, faced challenges from
                        the Chalukya king Mangalesha on the southern frontier of
                        his kingdom soon after his accession. The Kalachuris'
                        rule in Nashik was relatively brief, but their impact on
                        the region's history is evident through their coinage
                        and administrative practices.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Chalukyas of Badami
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The Chalukyas of Badami emerged as a dominant force in
                        the first half of the 6th century CE. Pulakeshin I, the
                        dynasty's first independent ruler, ruled from 543 CE. He
                        performed various Vedic sacrifices, including the
                        Ashvamedha, a symbol of his sovereignty. His son,
                        Kirtivarman I, expanded the Chalukya empire through
                        conquests in South India. He was known for his military
                        prowess and was described as a "night of destruction" to
                        his enemies.
                    </p>
                    <p className="">
                        Mangalesha, Kirtivarman I's younger brother, succeeded
                        him but faced internal strife and lost his life in a
                        civil war. Pulakeshin II, Mangalesha's nephew, ascended
                        to the throne and established Badami as his capital. The
                        Chinese pilgrim Hiuen Tsang referred to Pulakeshin II as
                        the "lord of Maharashtra," indicating his influence in
                        the region. Scholars have debated the exact location of
                        Pulakeshin II's capital, but Nasik is considered the
                        most likely candidate.
                    </p>
                    <p className="">
                        Pulakeshin II's reign was marked by significant
                        achievements, including the grant of villages to
                        Brahmanas. However, his reign was tragically cut short
                        by the Pallava king Narasimhavarman, who conquered
                        Vatapi and defeated Pulakeshin II in battle around 642
                        CE. Pulakeshin II's son, Vikramaditya I, succeeded him
                        after a prolonged struggle. Vikramaditya appointed his
                        younger brother Dharashraya Jayasimha to govern South
                        Gujarat, North Konkan, and the Nasik district.
                        Jayasimha's rule is documented in his Nasik plates,
                        dated 685 CE. These plates record his grant of the
                        village Dhondhaka.
                    </p>
                    <p className="">
                        Another significant family, descended from
                        Harishchandra, ruled over Northern Konkan and the Nasik
                        district during the 7th and 8th centuries CE. Founded by
                        Svamichandra during the reign of Vikramaditya I, this
                        family's influence extended through three generations:
                        Svamichandra, Simhavarman, and Bhogashakti.
                        Bhogashakti's rule was marked by his resettlement
                        efforts and grants to merchants. However, his successor
                        was likely overthrown by the Rashtrakuta king
                        Dantidurga, who occupied the Nasik district around 715
                        CE. The Chalukyas of Badami's influence on Nashik was
                        significant, lasting through multiple generations. Their
                        rule marked a period of political and cultural
                        development in the region.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Rashtrakutas
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The Rashtrakutas, who succeeded the Chalukyas in the
                        Deccan, originally hailed from Lattalura. Their rise to
                        power is closely linked to Dantidurga, who expanded
                        their territory through extensive conquests.
                        Dantidurga's uncle, Krishna I, completed the conquest of
                        the Early Chalukyas, establishing the Rashtrakutas as a
                        dominant force in the region.
                    </p>
                    <p className="">
                        The Rashtrakuta dynasty produced several renowned
                        conquerors, including Druva and Govinda II, who led
                        successful military campaigns in North and South India.
                        Govinda III's rule is documented through several
                        copper-plate grants found in Maharashtra. One such
                        grant, discovered at Wani in the Nasik district, dates
                        back to 808 CE and records his donation of a village to
                        a Brahmana.
                    </p>
                    <p className="">
                        Amoghavarsha I, Govinda III's son, succeeded him.
                        Despite his peaceful nature, Amoghavarsha's reign was
                        marked by internal struggles and rebellions. His
                        successor, Govinda IV, was eventually overthrown by his
                        uncle Baddiga-Amogha varsha III, who placed his son
                        Krishna III on the throne.
                    </p>
                    <p className="">
                        Krishna III, known for his ambition and administrative
                        skills, led an expedition in North India and captured
                        the forts of Kalanjara and Chitrakuta. His reign marked
                        the continuation of the Rashtrakuta dynasty's power and
                        influence in Nashik and the surrounding regions.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Later Chalukyas
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The decline of the Rashtrakuta dynasty created a power
                        vacuum in the region. Tailap II, a Mahasamanta of the
                        Rashtrakutas, seized this opportunity to establish the
                        Later Chalukya dynasty.
                    </p>
                    <p className="">
                        The Paramara king Vakpati Munja planned to invade
                        Chalukya territory but was dissuaded by his wise
                        minister Rudraditya. Ignoring this advice, Munja was
                        captured by Tailapa and imprisoned. The story goes that
                        Munja fell in love with Tailapa's sister Mrinalavati and
                        revealed his escape plan to her. She betrayed him, and
                        Tailapa had him punished.
                    </p>
                    <p className="">
                        One of the most notable successors of Tailapa II was
                        Vikramaditya VI, the founder of the Chalukya-Vikrama
                        Samvat. He ascended the throne in 1075 CE. The Later
                        Chalukya dynasty's rule in Nashik continued until 1157
                        CE, when the last Chalukya king, Tailap III, was
                        overthrown by his Commander-in-Chief, the Kalachuri
                        Bijjala.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under the Yadavas
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The Yadavas of Devagiri emerged as a prominent force in
                        the late 12th century CE. Previously ruling as
                        feudatories of the Chalukyas of Kalyani, the Yadavas
                        established their own kingdom in Seunadesha (Khandesh).
                        Dridhaprahara, the family's founder, ruled from
                        Shrinagara, later identified as Chandradityapura (modern
                        Chandor in the Nasik district). His son Seunachandra
                        expanded the Yadava territory, establishing the city of
                        Seunpur/Sindiner (Sinnar). A minor branch of the Yadava
                        family ruled over a small district centered around
                        Anjaneri. Seunadeva, the ruler of this branch, made
                        grants to a Jain temple.
                    </p>
                    <p className="">
                        The Yadava dynasty witnessed several notable rulers. In
                        1294 CE, Alauddin Khalji invaded the kingdom of
                        Ramachandra, forcing him to pay a heavy ransom.
                        Ramachandra continued to rule until at least 1310 CE,
                        when he was succeeded by his son Shankaragana.
                    </p>
                    <p className="">
                        Shankaragana's defiance of Delhi led to his defeat and
                        death at the hands of Malik Kafur. Ramachandra's
                        son-in-law, Harapaladeva, briefly ousted the Muslims but
                        was unable to maintain control. The Hindu kingdom of
                        Devagiri ultimately fell in 1318 CE. The Yadava era
                        witnessed a unique architectural style known as
                        Hemadpanti, named after Hemadri or Hemadpant, a minister
                        of Mahadeva and Ramachandra. Temples built in this style
                        are prevalent throughout Maharashtra.
                    </p>
                    <p className="">
                        Marathi literature also flourished during the Yadava
                        period. Chakradhara, the founder of the Mahanubhava
                        cult, used Marathi as the medium of his religious
                        teachings, inspiring other writers to follow suit. The
                        Yadavas' rule in Nashik marked the final chapter of
                        Hindu dominance in the region before the arrival of
                        Muslim rule. Their legacy is evident in the
                        architectural and cultural developments that shaped the
                        region's identity.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under Maratha Rule
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The Nashik region remained under Mughal control until
                        the death of Aurangzeb in 1707 CE. Following this, the
                        Marathas gained control of the area.
                    </p>
                    <p className="">
                        During the Maratha period, Nashik witnessed significant
                        development and construction. Chieftain Naroshankar Raje
                        Bahadar built the Rameshwara temple and hung the
                        Naroshankar bell there. The Kapaleshwara temple was
                        constructed in 1738, and Chieftain Chandrachud built the
                        Sundar Narayana temple in 1756. Chieftain Aadekar
                        rebuilt the Kalaram temple in 1790.
                    </p>
                    <p className="">
                        The Maratha period was also marked by conflicts with the
                        Nizams of Hyderabad. Despite these tensions, Nashik
                        gained prominence and became an important city under
                        Maratha rule. In 1751, after Nizam's death, the Marathas
                        adopted the name "Nashik" for the city, replacing its
                        previous name, "Gulshanabad."
                    </p>
                    <p className="">
                        The Marathas continued to rule Nashik until 1818. In
                        that year, Thomas Hyslow and the British army conquered
                        Kopargaon and the northern part of Chandwad. They
                        followed this by capturing Thalner and the Chandwad fort
                        in March 1818, ultimately gaining control of the entire
                        Nashik region.
                    </p>
                </div>
                <h4 className="text-3xl mt-6 mb-4 font-bold">
                    Nashik Under British Rule
                </h4>
                <div className="text-base gap-2 flex flex-col">
                    <p className="">
                        The British conquest of the Maratha kingdom led to
                        Nashik being declared an important city and division.
                        However, British rule was met with resistance from the
                        local Bhilla community. Led by Magoji Naik, the Bhilla
                        people staged a significant protest, involving thousands
                        of participants. Despite facing opposition from the
                        British army, the Bhilla people continued their
                        struggle. Bhogoji Naik emerged as another prominent
                        leader of the resistance movement. Although the British
                        ultimately regained control of the region, the Bhilla
                        people's resistance left a lasting mark on Nashik's
                        history.
                    </p>
                    <p className="">
                        In 1860, Nashik was granted the status of a separate
                        district. The British established an Anglo-vernacular
                        school in 1861 and granted Nashik city Nagar Parishad
                        (municipal council) status in 1864. The first newspaper
                        in Nashik, the "Nashik News," was also launched during
                        this period. Prominent social reformers Gopal Hari
                        Deshmukh and Nyayamurthi Mahadev Ranade became active in
                        Nashik's social life in 1877. The Nasik Tram, operating
                        from the Old Municipal Building to Nasik Road railway
                        station, served the city for nearly 44 years, starting
                        in 1889.
                    </p>
                    <p className="">
                        Veer Vinayak Sawarkar, a native of Bhagur in Nashik,
                        secretly founded the Rashtrabhakta organization in 1899.
                        The "Mitramela" group also gained prominence in Nashik.
                        Sawarkar's activities led to his arrest and imprisonment
                        by the British government. Lokmanya Tilak conducted a
                        protest march against British rule in Nashik on May 31,
                        1907. Another notable figure involved in the freedom
                        movement was Hutatma Anant Kanhere, who participated in
                        the Mitramela group and social work.
                    </p>
                    <p className="">
                        Kanhere's assassination of Mr. Jackson, a cruel
                        collector of Nashik, in 1909 led to his arrest and
                        execution. Other activists like Krishna Gopal Karve,
                        Narayan Joshi, and Ganesh Joshi were also implicated in
                        the case and faced imprisonment. Dr. Babasaheb Ambedkar,
                        a prominent social reformer, contributed significantly
                        to Nashik's development. He fought for the rights of
                        untouchable people and led the Kalaram Satyagraha, a
                        movement demanding their entry into Hindu temples.
                    </p>
                    <p>
                        The British period in Nashik was characterized by both
                        resistance to colonial rule and social and economic
                        development. The city's history during this time
                        reflects the struggles and aspirations of its people.
                    </p>
                </div>
            </div>
        </div>
    );
}
