import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routers from './common/Router';
// import StickyNavbar from './components/Navbar';

function App() {
  return (
    <div className="App overflow-auto bg-white w-screen h-screen">
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </div>
  );
}

export default App;