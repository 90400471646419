import About from "../pages/About"
import ContactUs from "../pages/Contact"
import Home from "../pages/Home"
import Updates from "../pages/Updates"
import History from "../pages/History"

export const Links = [
    {
        name: "Home",
        path: "/",
        element: <Home />,
        showInNavigation: true,
    },
    {
        name: "Contact Us",
        path: "/contact",
        element: <ContactUs />,
        showInNavigation: true,
    },
    {
        name: "About Us",
        path: "/about",
        element: <About />,
        showInNavigation: true,
    },
    {
        name: "Updates",
        path: "/updates",
        element: <Updates />,
        showInNavigation: true,
    },
    {
        name: "History",
        path: "/history",
        element: <History />,
        showInNavigation: true,
    }
]